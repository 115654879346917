import React, { Fragment, useState, useEffect } from "react";
import { Container, FormControlLabel, Radio, Button } from "@material-ui/core";
import ArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { Card } from "bootstrap-4-react";
import "../style/Faq.scss";
import Meta from "../meta/Meta";
import Appbar from "../components/Appbar";
import { makeStyles } from "@material-ui/core/styles";
import SanitizedHTML from "react-sanitized-html";
import axios from "axios";
import { apiURL, isLogin } from "../utils";
import Footer from "../components/Footer";
import SubjectFaq from "./SubjectFaq";
import {
  ArrowDownward,
  ArrowDropDown,
  RadioButtonCheckedOutlined,
  RadioButtonUnchecked,
  Translate,
} from "@material-ui/icons";
import { FaLanguage } from "@react-icons/all-files/fa/FaLanguage";
import AskMoreQuesDialog from "./AskMoreQuesDialog";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
const panels = [
  {
    id: 0,
    label: "Should we do all the activities given in the book?",
    content:
      "The activities given in the book are to clarify the context and the concept...",
  },
  {
    id: 1,
    label: "Are warm up activities required to be done?",
    content: "Yes. They are important to set the tempo of learning...",
  },
  {
    id: 2,
    label: "Do we need to read and explain the poem?",
    content: "This is a matter of teaching technique...",
  },
  {
    id: 3,
    label: "Is Project work to be done?",
    content:
      "Yes, it is better done. It will take the children beyond the course/textbook...",
  },
  {
    fldi_id: 4,
    fldv_title:
      "Should Essay writing be done when it is not in the Activity Sheet?",
    fldt_description:
      "Essay writing can help in thinking deeply on all aspects of a given topic...",
  },
];

const Faq = () => {
  const [openAns, setOpenAns] = useState(null);
  const [selectedSubject, setSelectedSubject] = useState("English");
  const [faqData, setFaqData] = useState({
    General: [],
    English: [],
    Mathematics: [],
    Science: [],
    Hindi: [],
    Marathi: [],
    History: [],
  });
  const [language, setLanguage] = useState("");
  const [filteredGeneralFaqs, setFilteredGeneralFaqs] = useState([]);
  const [filteredSubjectFaqs, setFilteredSubjectFaqs] = useState([]);
  const [openAskMore, setAskMore] = useState(false);
  const history = useHistory();
  useEffect(() => {
    fetchFaqData();
    document.getElementById("faqContainer").scrollIntoView();
  }, []);
  useEffect(() => {
    if (filteredGeneralFaqs.length > 0) {
      setOpenAns(filteredGeneralFaqs[0].fldi_id);
    } else {
      setOpenAns(null); // Reset if no FAQs are available
    }
  }, [filteredGeneralFaqs]);

  useEffect(() => {
    filterFaqData(language);
  }, [faqData, language, selectedSubject]);

  const handleOpenAns = (id) => {
    setOpenAns((prevOpenAns) => (prevOpenAns === id ? null : id));
  };

  const fetchFaqData = () => {
    console.log("Calling...")
    const headers = {
      "Content-type": "application/json",
    };
    const body = {
      // user_id: getID(),
    };
    axios
      .post(apiURL("faq/get_all_faq"), body, {
        headers: headers,
      })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true) {
          setFaqData({
            General: resultData?.result?.General,
            English: resultData?.result?.English,
            Mathematics: resultData?.result?.Mathematics,
            Science: resultData?.result?.Science,
            Hindi: resultData?.result?.Hindi,
            Marathi: resultData?.result?.Marathi,
            History: resultData?.result?.History,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleChange = (e) => {
    setLanguage(e.target.value);
  };

  const filterFaqData = (language) => {
    const filterByLanguage = (faqs) => {
      console.log("faqs", faqs)
      if (!language || language === "All") {
        return faqs;
      }
      return faqs.filter((faq) => faq?.fldv_language === language);
    };

    setFilteredGeneralFaqs(filterByLanguage(faqData.General));
    setFilteredSubjectFaqs(filterByLanguage(faqData[selectedSubject]));
  };
  const handleOpenAskMore = () => {
    if (!isLogin()) {
      history.push("/sign");
    } else {
      setAskMore(true);
    }
  };
  const handleAskMoreClose = (e) => {
    setAskMore(false);
  };
  const labelClass = `label ${language ? "float" : ""}`;
  return (
    <Fragment>
      <Meta tableName={"menu"} objectId={17} forPage={"main_page"} />
      <Helmet>
        <title>FAQ</title>

        <meta charset="utf-8" />
        <link rel="icon" href="/navdisha-favicon.png" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="theme-color" content="#000000" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta name="robots" content="FAQ" />
        <link rel="canonical" href={window.location.href} />

        <meta name="description" content="FAQ" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <Appbar />
      <section className="BannerBox discussionBanner faqBanner " id="faqContainer">
        <div className="sixteen-nine">
          <div className="content text-center text-white d-none">
            <h2>Faq</h2>
            <p>Lorem Ipsum</p>
          </div>
        </div>
      </section>
      <section className="mainHeaderFAQ">
        <Container maxWidth="lg" className="container" >
          <p>
            Welcome to our FAQs page. Here, you'll find answers to common
            questions, organized into General FAQs and Subject-Specific FAQs.
            Below, we highlight five questions we've frequently encountered from
            our interactions with teachers.
          </p>
          <h2>Explore These Sections To Find The Information You Need.</h2>
          <div className="filter">
            <div className="languageFaqFilter">
              <div className="formControlFaq">
                <label htmlFor="language-select" className={labelClass}>
                  <FaLanguage className="langSvg" />
                  <span> Language</span>
                </label>
                <select
                  id="language-select"
                  value={language}
                  onChange={(e) => handleChange(e)}
                  className="selectFaq"
                  onFocus={(e) => e.target.classList.add("focused")}
                  onBlur={(e) => e.target.classList.remove("focused")}
                >
                  <option value="">All</option>
                  <option value="English">English</option>
                  <option value="Marathi">Marathi</option>
                </select>
                <ArrowDropDown className="icon" />
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section className="faqSection">
        <Container maxWidth="lg" className="container">
          <h2>General FAQ's</h2>
          <div className="faqContent">
            <div className="questionList">
              <Card className="questionCard">
                <ul>
                  {filteredGeneralFaqs && filteredGeneralFaqs?.map((list) => (
                    <li
                      key={list.id}
                      onClick={() => handleOpenAns(list.fldi_id)}
                      className={openAns === list?.fldi_id ? "selected" : ""}
                    >
                      <div className="labelList">
                        <div className="listIcon">
                          {openAns === list?.fldi_id ? (
                            <RadioButtonCheckedOutlined />
                          ) : (
                            <RadioButtonUnchecked />
                          )}
                        </div>
                        <div className="questionLabel">{list?.fldv_title}</div>
                        {/* <FormControlLabel
                        className="formControlLabel"
                        control={
                          <Radio
                            checked={openAns === list.fldi_id}
                            className="radio"
                          />
                        }
                        label=
                      /> */}
                      </div>
                      <ArrowRightIcon />
                    </li>
                  ))}
                </ul>
              </Card>
            </div>
            <div className="answerList">
              {filteredGeneralFaqs && filteredGeneralFaqs?.map(
                (ans) =>
                  openAns === ans?.fldi_id && (
                    <Card key={ans?.fldi_id} className="answerCard">
                      <div className="faqAnswer">
                        <div className="question">{ans?.fldv_title}</div>
                        <div className="answer">
                          {" "}
                          <SanitizedHTML
                            allowedAttributes={false}
                            allowedTags={false}
                            html={ans?.fldt_description}
                          />
                        </div>
                      </div>
                    </Card>
                  )
              )}
            </div>
          </div>
        </Container>
        {filteredGeneralFaqs.length === 0 &&
            <div className="faq-no-result-text">No Results</div>}
      </section>
      <section className="subjectFaq">
        <h2>Subject FAQ's</h2>
        <div className="buttons-container">
          <Button
            className={`button ${selectedSubject === "English" ? "active" : ""
              }`}
            onClick={() => setSelectedSubject("English")}
          >
            English
          </Button>
          <Button
            className={`button ${selectedSubject === "Mathematics" ? "active" : ""
              }`}
            onClick={() => setSelectedSubject("Mathematics")}
          >
            Mathematics
          </Button>
          <Button
            className={`button ${selectedSubject === "Science" ? "active" : ""
              }`}
            onClick={() => setSelectedSubject("Science")}
          >
            Science
          </Button>
          <Button
            className={`button ${selectedSubject === "Hindi" ? "active" : ""}`}
            onClick={() => setSelectedSubject("Hindi")}
          >
            Hindi
          </Button>
          <Button
            className={`button ${selectedSubject === "Marathi" ? "active" : ""
              }`}
            onClick={() => setSelectedSubject("Marathi")}
          >
            Marathi
          </Button>
          <Button
            className={`button ${selectedSubject === "History" ? "active" : ""
              }`}
            onClick={() => setSelectedSubject("History")}
          >
            History
          </Button>
        </div>
      </section>
      {filteredSubjectFaqs && filteredSubjectFaqs?.length > 0 && <SubjectFaq faqs={filteredSubjectFaqs} />}
      {filteredSubjectFaqs.length === 0 &&
        <div className="upcoming-webinar-no-result-text">No Results</div>}
      <section className="askMore">
        <div className="askMoreContainer">
          <Button onClick={handleOpenAskMore}>Ask Other Questions</Button>
        </div>
      </section>
      <AskMoreQuesDialog open={openAskMore} handleClose={handleAskMoreClose} />
      <Footer />
    </Fragment>
  );
};

export default Faq;
