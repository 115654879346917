import React, { Component } from "react";
import Navmenu from "../components/Navmenu";
import Appbar from "../components/Appbar";
import "../style/training.scss";
import { Container, Row, Col, Card } from "bootstrap-4-react";
import Footer from "../components/Footer";
import Button from "@material-ui/core/Button";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import { Link } from "react-router-dom";
import * as yup from "yup";
import { Formik, Form, Field } from "formik";
import { withStyles } from "@material-ui/core/styles";
import { IoMdCheckmarkCircleOutline } from "@react-icons/all-files/io/IoMdCheckmarkCircleOutline";
import { FiSunset } from "@react-icons/all-files/fi/FiSunset";
import {
  apiURL,
  isLogin,
  getName,
  getEmail,
  getContact,
  getID,
  getLastName,
  getRole,
  getBoard,
  getGrades,
  getSubjects,
  getCity,
  getPincode,
  getSchCollName,
} from "../utils";
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  Snackbar,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import axios from "axios";
import Moment from "moment";
import Sign from "../login/Sign";
import Meta from "../meta/Meta";
import RegistrationForm from "./RegistrationForm";
import {
  BookmarkBorder,
  DateRange,
  Bookmark,
  ArrowDropDown,
  Translate,
  PermCameraMic,
  CloseOutlined,
} from "@material-ui/icons";
import { FaLanguage } from "@react-icons/all-files/fa/FaLanguage";
import { Spinner } from "react-bootstrap";
import ArticleImageLoader from "../components/Article/ArticleImageLoader";
const styles = (theme) => ({
  formControl: {
    minWidth: 200,
    marginRight: theme.spacing(2),
    position: "relative",
    display: "flex",
    alignItems: "center",
  },
  select: {
    width: "100%",
    padding: "10px 30px 10px 10px",
    borderColor: "#064e89",
    borderWidth: "2px",
    borderRadius: "4px",
    appearance: "none",
    backgroundColor: "white",
    fontSize: "16px",
    fontWeight: "bold",
    color: "#064e89",
  },
  icon: {
    position: "absolute",
    right: "10px",
    top: "50%",
    transform: "translateY(-50%)",
    pointerEvents: "none",
    color: "#064e89",
  },
  label: {
    position: "absolute",
    left: "10px",
    top: "50%",
    transform: "translateY(-50%)",
    backgroundColor: "white",
    padding: "0 5px",
    color: "#064e89",
    fontWeight: "bold",
    transition: "0.2s",
    pointerEvents: "none",
    transformOrigin: "top left",
    "&.float": {
      top: "-10px",
      transform: "scale(0.75)",
    },
  },
  filter: {
    position: "absolute",
    textAlign: "center",
    display: "block",
    marginTop: "2rem",
    width: "100%",
    fontSize: "1rem",
  },
  filter_group_year: {
    marginRight: "1rem",
    marginLeft: "3rem",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0.5rem",
      marginRight: "2rem",
      fontSize: "0.8rem",
    },
  },
  filter_group_lan: {
    marginLeft: "1rem",
    [theme.breakpoints.down("sm")]: {
      marginRight: "1rem",
      fontSize: "0.8rem",
    },
  },
  dialogPaper: {
    // minHeight: '90vh',
    // maxHeight: '90vh',
    borderRadius: "25px",
    maxWidth: "48.75rem",
  },
});

const emailVerified = localStorage.getItem("isEmailVerified");
class Upcoming_webinar extends Component {
  _isMounted = false;
  navMenuRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      upcomeingEventsList: [],
      noData: "",
      registerDialog: false,
      showComponent: false,
      userData: [],
      title: "",
      id: "",
      joinNow: "",
      alreadyRegister: false,
      userId: "",
      showSnackbar: false,
      snackBarMessage: "",
      severityColor: "",
      snackBarTimer: "",
      page: 1,
      per: 4,
      loading: false,
      authEmail: "",
      otpBoxOpen: false,
      isEmailAdresssVerified: false,
      email: "",
      emailError: false,
      emailErrorMsg: "",
      isEmailVerified: false,
      isEmailVerifiedMsg: "",
      open: false,
      openSubjectDrop: false,
      selectedLan: "",
      selectedMode: "",
      isRegistered: false
    };
    this.getUpComeinEvents = this.getUpComeinEvents.bind(this);
    this.getUserData = this.getUserData.bind(this);
    // this.registerNow = this.registerNow.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    window.scroll(0, 0);
    this.getUpComeinEvents();
    this.getUserData();
    this.scrollListener = window.addEventListener("scroll", (e) => {
      this.handleScroll(e);
    });
    if (this.navMenuRef.current) {
      this.navMenuRef.current.getMenus();
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.registerDialog !== this.state.registerDialog &&
      this.state.registerDialog
    ) {
      this.getUserData();
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener("scroll", this.handleScroll);
  }

  snackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ showSnackbar: false });
  };

  getUserData = () => {
    const body = {
      fldi_id: getID(),
    };
    const headers = {
      "content-type": "application/json",
      Accept: "application/json",
    };
    axios
      .post(apiURL("teacher/get_profile"), body, {
        headers: headers,
      })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          const firstname =
            resultData.result.fldv_first_name !== ""
              ? resultData.result.fldv_first_name
              : resultData.result.fldv_name;
          const lastname =
            resultData.result.fldv_last_name !== ""
              ? resultData.result.fldv_last_name
              : resultData.result.fldv_surname;
          const role = resultData.result.fldv_faculty_status;
          const contact = resultData.result.fldv_contact;
          const email = resultData.result.fldv_email;
          const city = resultData.result.fldi_city;
          const schcollname = resultData.result.fldv_school_college;
          const pincode = resultData.result.fldv_pincode;
          const board = resultData.result.fldv_board_medium;

          const grades = resultData.result.fldv_grades_taught
            ? resultData.result.fldv_grades_taught
                .split(",")
                .map((grade) => grade.trim())
            : [];

          const subjects = resultData.result.fldv_subjects_taught
            ? resultData.result.fldv_subjects_taught
                .split(",")
                .map((sub) => sub.trim())
            : [];
          const userData = {
            firstname,
            lastname,
            role,
            city,
            contact,
            email,
            schcollname,
            pincode,
            board,
            grades,
            subjects,
          };
          this.setState({
            userData: userData,
          });
        } else {
          this.setState({
            noData: "Coming Soon",
            loading: false,
            scrolling: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  getUpComeinEvents = () => {
    this.setState({ loading: true });
    const { per, page, upcomeingEventsList } = this.state;
    let updatedEventsList;
    const headers = {
      "content-type": "application/json",
      Accept: "application/json",
    };
    const body = {
      status: 1,
      event_type: "UE",
      user_id: isLogin() ? getID() : this.state.userId,
    };

    axios
      .post(apiURL("webinar/events?per_page=" + per + "&page=" + page), body, {
        headers: headers,
      })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          updatedEventsList = [
            ...upcomeingEventsList,
            ...resultData.result,
          ];          
          this.setState({
            upcomeingEventsList: updatedEventsList,
            scrolling: false,
          });
          this.setState({ loading: false, scrolling: true });
          // Fetching the registered users for the upcoming events here.
      updatedEventsList.map((event)=>{
        const body = {
          webinar_id: event.fldi_id,
          user_id: getID(),
        };
        axios
        .post(apiURL("join_webinar_user/check_join_user"), body, {
          headers: headers,
        })
        .then((response) => {
          const resultData = response.data;
          if (resultData.status === true || resultData.status === 1) {
            this.state.isRegistered = true;
          }
        })
      })
        } else {
          this.setState({
            noData: "Coming Soon",
            loading: false,
            scrolling: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  loadMore = () => {
    if (this._isMounted) {
      this.setState(
        (prevState) => ({
          page: prevState.page + 1,
          scrolling: false,
        }),
        this.getUpComeinEvents
      );
    }
  };
  handleScroll = () => {
    if (this._isMounted) {
      var lastLi = document.querySelector(".upcomingEvent");
      if (lastLi !== null) {
        var lastLiOffset = lastLi.offsetTop + lastLi.clientHeight;
        var pageOffset = window.pageYOffset + window.innerHeight;
        if (pageOffset > lastLiOffset) {
          if (this.state.scrolling) {
            this.loadMore();
          }
        }
      }
    }
  };
  handleKeyPress = (e) => {
    const keyCode = e.keyCode || e.which;
    const keyValue = String.fromCharCode(keyCode);
    const re = /^\d+$/;
    if (!re.test(keyValue)) {
      e.preventDefault();
    }
  };
  handleTextKeyPress = (event) => {
    const { name, value } = event.target;

    const regex = /^[a-zA-Z\s]*$/;

    if (!regex.test(event.key)) {
      event.preventDefault();
    }
  };

  handleRegistrationSuccess = (isJoined, webinarId) => {
    console.log(webinarId);
    const updatedEventsList = this.state.upcomeingEventsList.map((event) => {
      if (event.fldi_id === webinarId) {
        return { ...event, join_webinar: isJoined };
      }
      return event;
    });

    this.setState({ upcomeingEventsList: updatedEventsList });
  };
  registerForWebinar = (e, webinarId, title, date, time) => {
    e.stopPropagation();
    if (!isLogin()) {
      this.setState({ showComponent: true });
    } else {
      // this.getUpComeinEvents();
      this.setState({
        registerDialog: true,
        id: webinarId,
        title: title,
        date: date,
        time: time,
      });
    }
  };

  registerWebinarClose = () => {
    this.setState({ registerDialog: false, id: "", title: "" });
    this.getUserData();
  };
  handleReadMore = (e, id, title) => {
    e.preventDefault();
    if (!isLogin()) {
      this.setState({ showComponent: true });
      this.props.history.push("/sign");
    } else {
      this.props.history.push("/webinar/" + id + "/" + title);
    }
  };
  checkJoinNow = () => {
    this.getUpComeinEvents();
    this.setState({
      showComponent: false,
      //registerDialog: true,
      firstname: getName(),
      email: getEmail(),
      contact: getContact(),
      userId: getID(),
      id: this.state.id,
      title: this.state.title,
    });
  };

  handleLanguageChange = (e) => {
    const selectedLan = e.target.value;
    this.setState({ selectedLan });
  };
  handleAddBookMark = (e, id) => {
    e.stopPropagation();
    if (!isLogin()) {
      this.setState({ showComponent: true });
    } else {
      const headers = {
        "content-type": "application/json",
        Accept: "application/json",
      };
      const body = {
        content_id: id,
        content_type: "past_webinar",
        user_id: getID(),
      };
      axios
        .post(apiURL("bookmark/addBookmark"), body, { headers: headers })
        .then((response) => {
          const resultData = response.data;
          //alert(resultData.message);
          if (resultData.success === true || resultData.success === 1) {
            const index = this.state.upcomeingEventsList.findIndex(
              (event) => event.fldi_id === id
            );
            if (index !== -1) {
              const updatedEventsList = [...this.state.upcomeingEventsList];
              updatedEventsList[index].is_bookmark = true;
              this.setState({
                upcomeingEventsList: updatedEventsList,
              });
            }
          } else {
            console.log("error");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  handleRemoveBookMark = (e, id) => {
    e.stopPropagation();
    const headers = {
      "content-type": "application/json",
      Accept: "application/json",
    };
    const body = {
      content_id: id,
      content_type: "past_webinar",
      user_id: getID(),
    };
    axios
      .post(apiURL("bookmark/removeBookmark"), body, { headers: headers })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          const updatedEventsList = this.state.upcomeingEventsList.map(
            (event) => {
              if (event.fldi_id === id) {
                return {
                  ...event,
                  is_bookmark: false,
                };
              }
              return event;
            }
          );
          this.setState({
            upcomeingEventsList: updatedEventsList,
          });
        } else {
          console.log("error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  handleModeChange = (e) => {
    const selectedMode = e.target.value;
    this.setState({ selectedMode });
  };
  render() {
    const { classes } = this.props;
    const { selectedMode, selectedLan } = this.state;
    const loadingCSS = {
      height: "30px",
      margin: "10px",
    };
    const loadingTextCSS = {
      display: this.state.loading ? "block" : "none",
      textAlign: "center",
    };
    const filteredEvents = selectedMode
      ? this.state.upcomeingEventsList.filter(
          (event) =>
            event.fldv_mode === selectedMode &&
            (selectedLan === "" || event.fldv_language === selectedLan)
        )
      : this.state.upcomeingEventsList.filter(
          (event) => selectedLan === "" || event.fldv_language === selectedLan
        );        
    const labelLanClass = `${classes.label} ${selectedLan ? "float" : ""}`;
    const labelModeClass = `${classes.label} ${selectedMode ? "float" : ""}`;

    return (
      <React.Fragment>
        <Meta tableName={"menu"} objectId={10} forPage={"main_page"} />
        {this.state.showComponent ? (
          <Sign
            ref={this._sign}
            pageProp={this.props}
            checkJoinNow={(e) => this.checkJoinNow(e)}
          />
        ) : (
          <div>
            <Appbar />
            <div className="sticky-top sign-light">
              <Navmenu ref={this.navMenuRef} />
            </div>
            <section className="BannerBox TrainingBox d-none">
              <div class="sixteen-nine">
                <div class="content text-center text-white ">
                  <h2>Upcoming Events</h2>
                  <p>UPCOMING WEBINARS TO FEED YOUR BRAIN</p>
                </div>
              </div>
            </section>

            <section className="BannerBox TrainingBox upComeingWebinar">
              <div class="sixteen-nine">
                <div class="content text-center text-white invisible">
                  <h2>Past Webinars</h2>
                  <p className="text-uppercase">
                    Past Webinars TO FEED YOUR BRAIN
                  </p>
                </div>
              </div>
            </section>

            <section class="upcomingEvent">
              <Container>
                <div className="filter">
                  <div className="modeFilter">
                    <div className="formControl">
                      <label className={labelModeClass}>
                        <FiSunset className="yearSvg" />
                        <span> Mode</span>
                      </label>
                      <select
                        value={selectedMode}
                        onChange={this.handleModeChange}
                        className="select"
                        onFocus={(e) => e.target.classList.add("focused")}
                        onBlur={(e) => e.target.classList.remove("focused")}
                      >
                        <option value="">All</option>
                        <option value="Physical">Physical</option>
                        <option value="Online">Online</option>
                      </select>
                      <ArrowDropDown className="icon" />
                    </div>
                  </div>
                  <div className="languageFilter d-flex justify-content-center">
                    <div className="formControl">
                      <label
                        htmlFor="language-select"
                        className={labelLanClass}
                      >
                        <FaLanguage className="langSvg" />
                        <span>Language</span>
                      </label>
                      <select
                        id="language-select"
                        value={selectedLan}
                        onChange={this.handleLanguageChange}
                        className="select"
                        onFocus={(e) => e.target.classList.add("focused")}
                        onBlur={(e) => e.target.classList.remove("focused")}
                      >
                        <option value="">All</option>
                        <option value="English">English</option>
                        <option value="Marathi">Marathi</option>
                      </select>
                      <ArrowDropDown className="icon" />
                    </div>
                  </div>
                </div>
                <Row className="upcomingWeb position-relative d-flex">
                  {filteredEvents.map((arrList, index) => {
                    return (
                      <Col
                        col="12 col-lg-6 col-xl-4"
                        className="mx-auto custom-col-spacing"
                      >
                        <Card
                          style={{ cursor: "pointer" }}
                          onClick={(e) =>
                            this.handleReadMore(
                              e,
                              arrList.fldi_id,
                              arrList.fldv_title
                                .replace(/ /g, "-")
                                .toLowerCase()
                            )
                          }
                        >
                          <Card.Body>
                            <div className="position-relative">
                              {/* <div className="SpeakerBox text-center"> */}
                              <Grid container spacing={2}>
                                <Grid item xs={4}>
                                  <div className="Speaker">
                                    <Button
                                      className="mode"
                                      style={{
                                        background:
                                          arrList.fldv_mode === "Physical"
                                            ? "#064e89"
                                            : "#a03fa0",
                                      }}
                                    >
                                      {arrList.fldv_mode}
                                    </Button>
                                    <figure>
                                      <div className="d-block imgBox">
                                        <ArticleImageLoader
                                          imageUrl={arrList.fldv_image_url}
                                          altText={"img"}
                                        />
                                        {/* <img
                                          src={arrList.fldv_image_url}
                                          className="img-fluid"
                                          alt="img"
                                        /> */}
                                      </div>
                                      <p>{arrList.fldv_speaker_name}</p>
                                    </figure>
                                  </div>
                                </Grid>
                                <Grid item xs={8}>
                                  <table className="table">
                                    <tr>
                                      <th colSpan="2" className="topic">
                                        {arrList.fldv_title}
                                      </th>
                                    </tr>

                                    <tbody>
                                      <tr>
                                        <th>Date:</th>
                                        <td>
                                          {Moment(
                                            arrList.fldv_select_date
                                          ).format("ddd, D MMM, YYYY")}
                                        </td>
                                      </tr>
                                      <tr>
                                        <th>Time:</th>
                                        <td>
                                          {Moment(arrList.fldt_time).format(
                                            "LT"
                                          )}
                                        </td>
                                      </tr>
                                      <tr>
                                        <th>Duration:</th>
                                        <td>{arrList.fldv_duration} hrs</td>
                                      </tr>
                                      <tr>
                                        <th>Venue:</th>
                                        <td>{arrList.fldv_venue_location}</td>
                                      </tr>
                                    </tbody>
                                  </table>

                                  <div className="action">
                                    {arrList.join_webinar ? (
                                      <Button className="registered">
                                        {" "}
                                        Registered{" "}
                                        <span>
                                          <IoMdCheckmarkCircleOutline />
                                        </span>
                                      </Button>
                                    ) : (
                                      <Button
                                        className="readmore"
                                        disabled={Moment(arrList.fldv_select_date).isBefore(Moment(), 'day')}
                                        onClick={(e) =>
                                          this.registerForWebinar(
                                            e,
                                            arrList.fldi_id,
                                            arrList.fldv_title,
                                            Moment(
                                              arrList.fldv_select_date
                                            ).format("MM DD YYYY"),
                                            Moment(arrList.fldt_time).format(
                                              "LT"
                                            )
                                          )
                                        }
                                      >
                                        {" "}
                                        Register{" "}
                                      </Button>
                                    )}
                                    {arrList.is_bookmark ? (
                                      <IconButton
                                        style={{ color: "orange" }}
                                        className="bookmark"
                                        onClick={(e) =>
                                          this.handleRemoveBookMark(
                                            e,
                                            arrList.fldi_id
                                          )
                                        }
                                      >
                                        <Bookmark />
                                      </IconButton>
                                    ) : (
                                      <IconButton
                                        className="bookmark"
                                        onClick={(e) =>
                                          this.handleAddBookMark(
                                            e,
                                            arrList.fldi_id
                                          )
                                        }
                                      >
                                        <BookmarkBorder />
                                      </IconButton>
                                    )}
                                  </div>
                                </Grid>
                                {/* </div> */}
                              </Grid>
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                    );
                  })}
                   <Col col="sm-12" className="text-center noFound">
                    {" "}
                    {this.state.upcomeingEventsList.length === 0 && this.state.noData==="Coming Soon" && !selectedLan && !selectedMode && (
                      <h3>{this.state.noData}</h3>
                    )}
                  </Col>
                    {!this.state.loading && filteredEvents.length === 0 && (selectedLan ||selectedMode) &&
                      <div className="upcoming-webinar-no-result-text">No Results</div>}
                </Row>
                <div
                  ref={(loadingRef) => (this.loadingRef = loadingRef)}
                  style={loadingCSS}
                >
                  <span style={loadingTextCSS}>
                    <Spinner variant="primary" />
                  </span>
                  {/* <span>{this.state.scrolling ? this.state.noData : ""}</span> */}
                </div>
              </Container>
            </section>

            <div className="customFooter mt-0">
              <Footer />
            </div>
            <Dialog
              onClose={(e) => this.registerWebinarClose(e)}
              aria-labelledby="customized-dialog-title"
              open={this.state.registerDialog}
            >
              <div className="register-webinar-modal-header-mob">
                <DialogTitle
                  id="customized-dialog-title"
                  className="register-webinar-modal-header-mob-txt"
                  onClose={(e) => this.registerWebinarClose(e)}
                >
                  {this.state.title}
                </DialogTitle>
                <CloseOutlined className="register-webinar-modal-close-btn" onClick={(e) => this.registerWebinarClose(e)} />
              </div>
              <DialogContent>
                {/*<form id="login" onSubmit={(e) => this.registerNow(e)}>*/}
                <RegistrationForm
                  id={this.state.id}
                  userData={this.state.userData}
                  date={this.state.date}
                  time={this.state.time}
                  onClose={this.registerWebinarClose}
                  onSnackClose={this.snackBarClose}
                  componentUpdate={this.getUpComeinEvents}
                  onSuccess={(isJoined) =>
                    this.handleRegistrationSuccess(isJoined, this.state.id)
                  }
                />
                {/*</form>*/}
              </DialogContent>
              <DialogActions>
                {/*<Button autoFocus onClick={(e) => this.registerTrainingClose(e)} color="primary">
                  Save changes
                </Button>*/}
              </DialogActions>
            </Dialog>
            <Snackbar
              open={this.state.showSnackbar === true}
              autoHideDuration={this.state.snackBarTimer}
              onClose={this.snackBarClose}
            >
              <Alert
                onClose={this.snackBarClose}
                severity={this.state.severityColor}
                elevation={6}
                variant="filled"
              >
                {this.state.snackBarMessage}
              </Alert>
            </Snackbar>
          </div>
        )}
      </React.Fragment>
    );
  }
}
export default withStyles(styles)(Upcoming_webinar);
