import React, { Component } from "react";
import { Row, Col, Card } from "bootstrap-4-react";
import Navmenu from "../components/Navmenu";
import Footer from "../components/Footer";
import "../style/Glimpses.scss";
import { withStyles } from "@material-ui/core/styles";
import Appbar from "../components/Appbar";

import { IconButton, Container } from "@material-ui/core";

import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { apiURL, getID, isLogin } from "../utils";
import axios from "axios";
import Video_dialog from "../Video_dialog";

import { BookmarkBorder, PlayArrow, ArrowDropDown } from "@material-ui/icons";
import Moment from "moment";
import { Bookmark } from "@material-ui/icons";
import Rating from "../components/Rating";
import ShareNews from "../govt-circular-gr/ShareNews";
import { FaLanguage } from "@react-icons/all-files/fa/FaLanguage";
import Meta from "../meta/Meta.jsx";
import { Helmet } from "react-helmet";
import { Spinner } from "react-bootstrap";
const styles = (theme) => ({
  formControl: {
    minWidth: 200,
    marginRight: theme.spacing(2),
    position: "relative",
    display: "flex",
    alignItems: "center",
  },
  select: {
    width: "100%",
    padding: "10px 30px 10px 10px",
    borderColor: "#064e89",
    borderWidth: "2px",
    borderRadius: "4px",
    appearance: "none",
    backgroundColor: "white",
    fontSize: "16px",
    fontWeight: "bold",
    color: "#064e89",
  },
  icon: {
    position: "absolute",
    right: "10px",
    top: "50%",
    transform: "translateY(-50%)",
    pointerEvents: "none",
    color: "#064e89",
  },
  label: {
    position: "absolute",
    left: "10px",
    top: "50%",
    transform: "translateY(-50%)",
    backgroundColor: "white",
    padding: "0 5px",
    color: "#064e89",
    fontWeight: "bold",
    transition: "0.2s",
    pointerEvents: "none",
    transformOrigin: "top left",
    "&.float": {
      top: "-10px",
      transform: "scale(0.75)",
    },
  },
  filter: {
    position: "absolute",
    textAlign: "center",
    display: "block",
    marginTop: "2rem",
    width: "100%",
    fontSize: "1rem",
  },
  filter_group_year: {
    marginRight: "1rem",
    marginLeft: "3rem",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0.5rem",
      marginRight: "2rem",
      fontSize: "0.8rem",
    },
  },
  filter_group_lan: {
    marginLeft: "1rem",
    [theme.breakpoints.down("sm")]: {
      marginRight: "1rem",
      fontSize: "0.8rem",
    },
  },
  dialogPaper: {
    // minHeight: '90vh',
    // maxHeight: '90vh',
    borderRadius: "0px",
    maxWidth: "48.75rem",
    width: "60rem",
    //  height:'100%',
    // padding:'0rem',
  },

  dialog: {
    padding: "0rem",
  },

  paper: {
    padding: theme.spacing(0),
    textAlign: "justify",
    color: theme.palette.text.secondary,
    borderRadius: "0px",
    boxShadow: "0px 4px 10px -1px rgba(0,0,0,0.1)",
    height: "100%",
  },
});

class VideoGlimses extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      videoUrl: "",
      open: false,
      dialogOpen: false,
      isOpen: false,
      categoryList: [],
      videoList: [],
      categoryID: null,
      noData: "",
      selectedTab: 0,
      showComponent: false,
      showRating: false,
      selectedLan: "",
      page: 1,
      per: 4,
      loading: false,
      isMobile: false,
    };

    this.openModal = this.openModal.bind(this);
    this.videoCategory = this.videoCategory.bind(this);
    this.clickCategory = this.clickCategory.bind(this);
    this.videoListData = this.videoListData.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    window.scroll(0, 0);
    this.setState({ isMobile: window.innerWidth <= 400 });
    this.videoCategory();
    this.videoListData();
    this.scrollListener = window.addEventListener("scroll", (e) => {
      this.handleScroll(e);
    });
  }
  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener("scroll", this.handleScroll);
  }
  // componentDidUpdate(prevProps, prevState) {
  //   if (prevState.videoList !== this.state.videoList) {
  //     this.extractUniqueYears();
  //   }
  // }
  checkJoinNow = () => {
    //this.videoCategory();
    //this.videoListData();
    this.setState({
      showComponent: false,
      open: true,
      videoUrl: this.state.videoUrl,
    });
  };

  videoCategory = () => {
    const headers = {
      "Content-type": "application/json",
    };
    axios
      .get(apiURL("video_category/get_all_video_category"), {
        headers: headers,
      })
      .then((response) => {
        if (this._isMounted) {
          const resultData = response.data;
          if (resultData.status === true || resultData.status === 1) {
            this.setState({ categoryList: resultData.result });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  handleLanguageChange = (e) => {
    const selectedLan = e.target.value;
    this.setState({ selectedLan });
  };
  videoListData = () => {
    this.setState({ loading: true });
    const { per, page, videoList } = this.state;
    const headers = {
      "Content-type": "application/json",
    };
    const body = { category_id: this.state.categoryID, user_id: getID() };
    axios
      .post(
        apiURL("video/get_all_video?per_page=" + per + "&page=" + page),
        body,
        { headers: headers }
      )
      .then((response) => {
        if (this._isMounted) {
          const resultData = response.data;
          if (resultData.status === true || resultData.status === 1) {
            const updatedList = [...videoList, ...resultData.result];
            this.setState({
              videoList: updatedList,
              scrolling: false,
            });
            this.setState({ loading: false, scrolling: true });
          } else {
            this.setState({ noData: "No Results", loading: false, scrolling: false });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  loadMore = () => {
    if (this._isMounted) {
      this.setState(
        (prevState) => ({
          page: prevState.page + 1,
          scrolling: false,
        }),
        this.videoListData
      );
    }
  };
  handleScroll = () => {
    if (this._isMounted) {
      var lastLi = document.querySelector(".videoMain");
      if (lastLi !== null) {
        var lastLiOffset = lastLi.offsetTop + lastLi.clientHeight;
        var pageOffset = window.pageYOffset + window.innerHeight;
        if (pageOffset > lastLiOffset) {
          if (this.state.scrolling) {
            this.loadMore();
          }
        }
      }
    }
  };
  handleRating = (videoId, rating) => {
    if (!isLogin()) {
      this.setState({ showComponent: true });
    } else {
      this.setState({ showRating: true, videoId, videoId, rating: rating });
    }
  };
  handleAddBookMark = (e, id) => {
    if (!isLogin()) {
      this.setState({ showComponent: true });
    } else {
      const headers = {
        "content-type": "application/json",
        Accept: "application/json",
      };
      const body = {
        content_id: id,
        content_type: "video",
        user_id: getID(),
      };
      axios
        .post(apiURL("bookmark/addBookmark"), body, { headers: headers })
        .then((response) => {
          const resultData = response.data;
          //alert(resultData.message);
          if (resultData.success === true || resultData.success === 1) {
            const index = this.state.videoList.findIndex(
              (event) => event.fldi_id === id
            );
            if (index !== -1) {
              const updatedEventsList = [...this.state.videoList];
              updatedEventsList[index].is_bookmark = true;
              this.setState({
                videoList: updatedEventsList,
              });
            }
          } else {
            console.log("error");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  handleRemoveBookMark = (e, id) => {
    const headers = {
      "content-type": "application/json",
      Accept: "application/json",
    };
    const body = {
      content_id: id,
      content_type: "video",
      user_id: getID(),
    };
    axios
      .post(apiURL("bookmark/removeBookmark"), body, { headers: headers })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          const updatedEventsList = this.state.videoList.map((event) => {
            if (event.fldi_id === id) {
              return {
                ...event,
                is_bookmark: false,
              };
            }
            return event;
          });
          this.setState({
            videoList: updatedEventsList,
          });
        } else {
          console.log("error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  handleCloseRating = () => {
    this.setState({ showRating: false });
  };
  clickCategory = (e, id) => {
    //alert(id)
    this.setState(
      {
        categoryID: id,
        selectedTab: id,
      },
      () => {
        this.videoListData();
      }
    );
  };

  handleClickOpen = (e, videoUrl, id) => {
    if (!isLogin()) {
      this.props.history.push("/sign");
    }
    // else{
    this.setState({ open: true, videoUrl: videoUrl });
    this.addViews(id);
    //}
  };
  addViews = (id) => {
    const headers = {
      "Content-type": "application/json",
    };
    const body = {
      article_id: "",
      blog_id: "",
      video_id: id,
      user_id: getID(),
    };
    axios
      .post(apiURL("views/add_views"), body, { headers: headers })
      .then((response) => {
        if (this._isMounted) {
          const resultData = response.data;
          console.log(resultData);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  handleClose = () => {
    //this.setState({ open: false, videoUrl:'' })
    this.setState({
      open: false,
      videoUrl: "",
    });
  };

  openModal() {
    this.setState({ isOpen: true });
  }

  render() {
    const { classes } = this.props;
    const { showRating, selectedLan, isMobile } = this.state;
    const filteredVideos = selectedLan
      ? this.state.videoList.filter(
          (event) => event.fldv_video_language === selectedLan
        )
      : this.state.videoList;
    const loadingCSS = {
      height: "30px",
      margin: "10px",
    };
    const loadingTextCSS = {
      display: this.state.loading ? "block" : "none",
      textAlign: "center",
    };
    const labelLanClass = `${classes.label} ${selectedLan ? "float" : ""}`;
    return (
      <React.Fragment>
        <Meta tableName={"menu"} objectId={16} forPage={"main_page"} />
        <Helmet>
          <title>Videos</title>
          <meta charSet="utf-8" />
          <link rel="icon" href="/navdisha-favicon.png" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          <meta name="theme-color" content="#000000" />
          <meta http-equiv="cache-control" content="no-cache" />
          <meta http-equiv="expires" content="0" />
          <meta http-equiv="pragma" content="no-cache" />
          <meta name="robots" content="Videos" />
          <link rel="canonical" href={window.location.href} />
          <meta name="description" content="Videos" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
        </Helmet>
        <div>
          <Appbar />
          <div className="sticky-top sign-light">
            <Navmenu />
          </div>
          <section className="BannerBox videoBanner">
            <div class="sixteen-nine">
              <div class="content text-center text-white invisible">
                <h2>Videos</h2>
                <p>Lorem ipsume</p>
              </div>
            </div>
          </section>
          <section className="videoMain">
            <Container maxWidth="md" className="container">
              <div className="filter">
                <div className="languageVideoFilter">
                  <div className="formControlVideo">
                    <label htmlFor="language-select" className={labelLanClass}>
                      <FaLanguage className="langSvg" />
                      <span> Language</span>
                    </label>
                    <select
                      id="language-select"
                      value={selectedLan}
                      onChange={this.handleLanguageChange}
                      className="selectVideo"
                      onFocus={(e) => e.target.classList.add("focused")}
                      onBlur={(e) => e.target.classList.remove("focused")}
                    >
                      <option value="">All</option>
                      <option value="English">English</option>
                      <option value="Marathi">Marathi</option>
                    </select>
                    <ArrowDropDown className={classes.icon} />
                  </div>
                </div>
              </div>

              {filteredVideos.length > 0?
                <Row className="videolist position-relative d-flex">
                {filteredVideos.map((arrList, index) => {
                  return (
                    <Col
                      col="12 col-lg-6 col-xl-4"
                      className="mx-auto custom-col-spacing"
                    >
                      <Card>
                        {/* <Card.Body> */}
                        <div className="position-relative">
                          <div className="Speaker">
                            <figure
                              style={{ cursor: "pointer" }}
                              onClick={(e) => {
                                e.stopPropagation();
                                this.handleClickOpen(
                                  e,
                                  arrList.fldv_video,
                                  arrList.fldi_id
                                );
                              }}
                            >
                              <div className="d-block imgBox">
                                <img
                                  src={arrList.fldv_image_capture}
                                  className="img-fluid"
                                  alt="img"
                                />
                              </div>
                              {/* <p>{arrList.fldv_speaker_name}</p> */}
                            </figure>
                            <div className="playButton">
                              <IconButton
                                className="playButtonIcon"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  this.handleClickOpen(
                                    e,
                                    arrList.fldv_video,
                                    arrList.fldi_id
                                  );
                                }}
                              >
                                <PlayArrow />
                              </IconButton>
                            </div>
                            <div className="topic">{arrList.fldv_title}</div>
                            <div className="actionVideo">
                              {arrList.is_bookmark ? (
                                <IconButton
                                  style={{ color: "orange" }}
                                  className="bookmark"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    this.handleRemoveBookMark(
                                      e,
                                      arrList.fldi_id
                                    );
                                  }}
                                >
                                  <Bookmark />
                                </IconButton>
                              ) : (
                                <IconButton
                                  className="bookmark"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    this.handleAddBookMark(e, arrList.fldi_id);
                                  }}
                                >
                                  <BookmarkBorder />
                                </IconButton>
                              )}
                              <div className="rating">
                                <Rating
                                  videoId={arrList.fldi_id}
                                  userId={getID()}
                                  rating={arrList.rating}
                                  color="gold"
                                />
                              </div>

                              {/* </div> */}
                            </div>
                            <div className="share">
                              <ShareNews
                                webinarId={arrList.fldi_id}
                                shareUrl={
                                  arrList.fldv_video === ""
                                    ? "https://navdisha.navneet.com"
                                    : arrList.fldv_video
                                }
                                title={arrList.fldv_title}
                                date={Moment(arrList.fldv_select_date).format(
                                  "dddd, D MMM, YYYY"
                                )}
                                time={Moment(arrList.fldt_time).format(
                                  "hh:mmA"
                                )}
                              />
                            </div>
                          </div>

                          {/* </div> */}
                        </div>
                        {/* </Card.Body> */}
                      </Card>
                    </Col>
                  );
                })}
                <div className="clearfix"></div>
                {/* <Col col="sm-12" className="text-center noFound">
                  {" "}
                  <h3>{this.state.noData}</h3>
                </Col> */}
              </Row>:""
              }           
               {filteredVideos.length === 0 && !this.state.loading &&
                    <div className="upcoming-webinar-no-result-text">No Results</div>}
              <div
                ref={(loadingRef) => (this.loadingRef = loadingRef)}
                style={loadingCSS}
              >
                <span style={loadingTextCSS}>
                  <Spinner variant="primary" />
                </span>
                <span>{this.state.scrolling ? this.state.noData : ""}</span>
              </div>
            </Container>
          </section>

          <Video_dialog
            dialogOpen={this.state.open}
            videoUrl={this.state.videoUrl}
            onClose={(e) => this.handleClose(e)}
          />

          {/* <Dialog
          open={this.state.open}
          onClose={() =>
          this.handleClose()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          classes={{ paper: classes.dialogPaper }}   
          className="boxMain"
          >
              
          <DialogContent className="dialouge">
              <div className='player-wrapper'>
                <ReactPlayer
                url={this.state.videoUrl}
                className='react-player'
                width='100%'
                height='100%'
                config={{ file: { attributes: {
                autoPlay:false,
                muted: false
                }}}}
                />
              </div>
          </DialogContent>
        </Dialog>*/}

          <Footer />
        </div>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(VideoGlimses);
