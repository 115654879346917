import React from "react";

import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  TelegramShareButton,
  TelegramIcon,
  InstapaperShareButton,
  InstapaperIcon,
} from "react-share";
import "../style/ShareNews.scss";
import axios from "axios";
import { apiURL } from "../utils";
const ShareNews = ({
  shareUrl,
  title,
  date,
  time,
  webinarId,
  trainingId,
  articleId,
  blogId,
}) => {
  let shareTitle = title;
  if (date && time) {
    shareTitle += ` - Date: ${date}, Time: ${time}`;
  } else if (date) {
    shareTitle += ` - Date: ${date}`;
  }
  const handleShareCount = async () => {
    const headers = {
      "content-type": "application/json",
      Accept: "application/json",
    };
    const body = {
      webinar_id: webinarId || trainingId || "",
      // training_id: trainingId || "",
      //"number": this.state.mobileNumber,
      article_id: articleId || "",
      blog_id: blogId || "",
    };
    try {
      const response = await axios.post(apiURL("share/add_share"), body, {
        headers: headers,
      });
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="newsShare">
      {/*<div className="Demo__some-network">
            <InstapaperShareButton
              url={shareUrl}
              title={title}
              className="Demo__some-network__share-button"
            >
              <InstapaperIcon size={32} round />
            </InstapaperShareButton>
          </div>*/}

      <div className="Demo__some-network float-right sharebutton">
        <TwitterShareButton
          url={shareUrl}
          title={title}
          className="Demo__some-network__share-button"
          onClick={handleShareCount}
        >
          <TwitterIcon size={32} round />
        </TwitterShareButton>

        <div className="Demo__some-network__share-count">&nbsp;</div>
      </div>

      <div className="Demo__some-network float-right sharebutton">
        <LinkedinShareButton
          url={shareUrl}
          className="Demo__some-network__share-button"
          onClick={handleShareCount}
        >
          <LinkedinIcon size={32} round />
        </LinkedinShareButton>
      </div>

      <div className="Demo__some-network float-right sharebutton">
        <TelegramShareButton
          url={shareUrl}
          title={title}
          className="Demo__some-network__share-button"
          onClick={handleShareCount}
        >
          <TelegramIcon size={32} round />
        </TelegramShareButton>

        <div className="Demo__some-network__share-count">&nbsp;</div>
      </div>
      <div className="Demo__some-network float-right sharebutton">
        <FacebookShareButton
          url={shareUrl}
          quote={title}
          className="Demo__some-network__share-button"
          onClick={handleShareCount}
        >
          <FacebookIcon size={32} round />
        </FacebookShareButton>
      </div>
      <div className="Demo__some-network float-right sharebutton">
        <WhatsappShareButton
          url={shareUrl}
          title={shareTitle}
          separator=":: "
          className="Demo__some-network__share-button "
          onClick={handleShareCount}
        >
          <WhatsappIcon size={32} round />
        </WhatsappShareButton>
      </div>
    </div>
  );
};

export default ShareNews;
